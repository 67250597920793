import { useEffect, useRef } from 'react'

export default function useClickOutsideToClose(open, setOpen, numRefs = 1) {
    const refs = Array.from({ length: numRefs }, () => useRef(null))

    useEffect(() => {
        function handleWindowClick(event) {
            if (open && (!refs.some(ref => ref.current?.contains(event.target)))) {
                setOpen(false)
            }
        }
        window.addEventListener('click', handleWindowClick)
        return () => window.removeEventListener('click', handleWindowClick)
    }, [refs, open, setOpen])

    return refs
}
