import { TopBarContext } from './TopBarContext'

import Button from '../Button/Button'
import SvgIcon from '../SvgIcon/SvgIcon'
import useClickOutsideToClose from '../useClickOutsideToClose'
import { logDefaultHttpError } from '../LogUtils'
import * as i18n from '../i18n'

import * as style from './TopBar.module.css'

import { useContext, useEffect, useState } from 'react'

export default function TopBar({ isLogin }) {
    const {
        latestVersion,
        latestVersionLink,
        isMaintenanceMode,
        areJobsRunning,
        showLicenseExpired,
        showLicenseWarn,
        runningJobs,
        queuedJobs,
        queueEventTarget,
        user,
        version,
        companyName,
    } = useContext(TopBarContext)

    const [ showQueue, setShowQueue ] = useState(false)
    const [ showUserMenu, setShowUserMenu ] = useState(false)

    const [ queueRef, queueButtonRef ] = useClickOutsideToClose(showQueue, setShowQueue, 2)
    const [ userMenuRef, userMenuButtonRef ] = useClickOutsideToClose(showUserMenu, setShowUserMenu, 2)

    const [ animatePulseRunning, setAnimatePulseRunning ] = useState(false)
    const [ animatePulseQueued, setAnimatePulseQueued ] = useState(false)

    useEffect(() => {
        function handleNewRunningJob() {
            setAnimatePulseRunning(true)
            setTimeout(() => setAnimatePulseRunning(false), 600)
        }

        queueEventTarget.current.addEventListener('newrunningjob', handleNewRunningJob)

        return () => queueEventTarget.current.removeEventListener('newrunningjob', handleNewRunningJob)
    }, [ animatePulseRunning ])

    useEffect(() => {
        function handleNewQueuedJob() {
            setAnimatePulseQueued(true)
            setTimeout(() => setAnimatePulseQueued(false), 600)
        }

        queueEventTarget.current.addEventListener('newqueuedjob', handleNewQueuedJob)

        return () => queueEventTarget.current.removeEventListener('newqueuedjob', handleNewQueuedJob)
    }, [ animatePulseQueued ])

    async function interruptRunningJob(job) {
        const url = `/command-center/queue/interruptJob/${job.id}`
        const response = await fetch(url)

        if (!response.ok) {
            logDefaultHttpError(response.status, url)
            return
        }

        const json = await response.json()

        if (json.status !== 200) {
            logDefaultHttpError(json.status, url, json.message)
            return
        }
    }

    async function removeJobFromQueue(job) {
        const url = `/command-center/queue/removeJob/${job.id}`
        const response = await fetch(url)

        if (!response.ok) {
            logDefaultHttpError(response.status, url)
            return
        }

        const json = await response.json()

        if (json.status !== 200) {
            logDefaultHttpError(json.status, url, json.message)
            return
        }
    }

    async function clearQueue() {
        const url = '/command-center/queue/clear'
        const response = await fetch(url)

        if (!response.ok) {
            logDefaultHttpError(response.status, url)
            return
        }

        const json = await response.json()

        if (json.status !== 200) {
            logDefaultHttpError(json.status, url, json.message)
            return
        }
    }

    return (
        <div className={style.topBar}>
            <Button color='tintDark'
                    padding='small'
                    href='/command-center' >
                <SvgIcon src='icons/cc-logo-color.svg' className={style.logo} />
                <h1>Command Center</h1>
            </Button>

            <div className={style.itemGroup}>
                {latestVersion && // latest version of null means up-to-date
                    <Button color='tintDark'
                            padding='none'
                            href={latestVersionLink}
                            target='_blank' >
                        <div className={style.latestVersionMessage}>
                            <i18n.Message code='later.version.available' args={[ latestVersion ]} />
                        </div>
                    </Button>
                }

                {!isLogin &&
                    <>
                        {isMaintenanceMode &&
                            <div className={style.maintenanceModeMessage}>
                                <i18n.Message code={areJobsRunning ? 'system.entering.maintenance.mode' : 'system.in.maintenance.mode'} />
                            </div>
                        }

                        <Button color='tintDark'
                                padding='none'
                                title={i18n.getMessage(showLicenseExpired ? 'settings.expired.tooltip' : showLicenseWarn ? 'settings.warn.tooltip' : 'settings.tooltip')}
                                className={showLicenseExpired ? style.expiredSettingsButton : showLicenseWarn ? style.warnSettingsButton : ''}
                                href='/command-center/settings'>
                            <SvgIcon src='icons/settings.svg' alt={i18n.getMessage(showLicenseExpired ? 'settings.expired.tooltip' : showLicenseWarn ? 'settings.warn.tooltip' : 'settings.tooltip')} />
                        </Button>

                        <div className={style.queueButtonContainer} ref={queueButtonRef}>
                            {animatePulseRunning && <div className={style.pulseRunning}></div>}
                            {animatePulseQueued && <div className={style.pulseQueued}></div>}

                            <Button color='tintDark'
                                    padding='none'
                                    title={i18n.getMessage('queue.and.running.jobs.tooltip')}
                                    onClick={() => setShowQueue(value => !value)} >
                                <SvgIcon src='icons/queue.svg'
                                         alt={i18n.getMessage('queue.and.running.jobs.tooltip')}
                                         className={`${queuedJobs?.length > 1 ? style.multipleQueuedJobs : queuedJobs?.length > 0 ? style.singleQueuedJob : ''} ${runningJobs?.length > 0 ? style.runningJobs : ''}`} />
                            </Button>
                        </div>

                        <div ref={queueRef} className={`${style.queue} ${showQueue ? '' : style.hidden} hasScrollBar`}>
                            <div className={style.queueGroup}>
                                <h3><i18n.Message code='running.jobs.title' args={[ runningJobs?.length ?? 0 ]} /></h3>

                                <div className={style.queueList}>
                                    {runningJobs?.length > 0 ?
                                        runningJobs.map(job =>
                                            <div className={style.runningJobItem} key={job.id}>
                                                <SvgIcon src={job.type.icon.src} className={style.jobIcon} />

                                                <span>
                                                    <i18n.Message code={job.type.code}
                                                                  args={[ job.projectId > 0 ? job.projectName : job.tmsId > 0 ? job.tmsName : job.xtmProjectName?.trim() ? job.xtmProjectName : 'ERROR' ]} />
                                                </span>

                                                <div>
                                                    <Button color='tintDark'
                                                            size='small'
                                                            padding='none'
                                                            className={style.hoverButton}
                                                            href={!job.type.isSystem ?
                                                                    `/command-center/project/showProjectJobLog/${job.projectId}` :
                                                                  job.type.value === 'XTM_CALLBACK' ?
                                                                    '/command-center/tmsConnection/showXtmCallbackJobLog' :
                                                                  job.type.value === 'CHECK_SEND' ?
                                                                    '/command-center/tmsConnection/showCheckSendJobLog' :
                                                                    '/command-center/tmsConnection/showCheckImportJobLog'
                                                            }>
                                                        <SvgIcon src='icons/logs.svg' alt={i18n.getMessage('view.log.from.queue')} />
                                                    </Button>
                                                </div>

                                                <div>
                                                    {user.role.isAdminOrManager &&
                                                        <Button color='tintDark'
                                                                size='small'
                                                                padding='none'
                                                                className={style.hoverButton}
                                                                title={i18n.getMessage('kill.running.job')}
                                                                onClick={() => interruptRunningJob(job)} >
                                                            <SvgIcon src='icons/delete.svg'
                                                                     alt={i18n.getMessage('kill.running.job')} />
                                                        </Button>
                                                    }
                                                </div>
                                            </div>
                                        )
                                        :
                                        <p className='color-fg2'>
                                            <i18n.Message code='no.running.jobs' />
                                        </p>
                                    }
                                </div>
                            </div>

                            <div className={style.queueGroup}>
                                <h3><i18n.Message code='queued.jobs.title' args={[ queuedJobs?.length ?? 0 ]} /></h3>

                                {queuedJobs?.length > 0 && user.role.isAdminOrManager &&
                                    <Button color='tintDark'
                                            size='small'
                                            padding='none'
                                            className={style.clearQueueButton}
                                            title={i18n.getMessage('clear.queued.jobs')}
                                            onClick={clearQueue} >
                                        <SvgIcon src='icons/delete.svg' alt={i18n.getMessage('clear.queued.jobs')} />
                                    </Button>
                                }

                                <div className={style.queueList}>
                                    {queuedJobs?.length > 0 ?
                                        queuedJobs.map(job =>
                                            <div className={style.queuedJobItem} key={job.id}>
                                                <SvgIcon src={job.type.icon.src} className={style.jobIcon} />

                                                <span className={style.jobMessageArea}>
                                                    <i18n.Message code={job.type.code}
                                                                  args={[ job.projectId > 0 ? job.projectName : job.tmsId > 0 ? job.tmsName : job.xtmProjectName?.trim() ? job.xtmProjectName : 'ERROR' ]} />
                                                </span>

                                                <div>
                                                    {user.role.isAdminOrManager &&
                                                        <Button color='tintDark'
                                                                size='small'
                                                                padding='none'
                                                                className={style.hoverButton}
                                                                title={i18n.getMessage('delete.job.from.queue')}
                                                                onClick={() => removeJobFromQueue(job)} >
                                                            <SvgIcon src='icons/delete.svg'
                                                                     alt={i18n.getMessage('delete.job.from.queue')} />
                                                        </Button>
                                                    }
                                                </div>
                                            </div>
                                        )
                                        :
                                        <p className='color-fg2'>
                                            <i18n.Message code='no.queued.jobs' />
                                        </p>
                                    }
                                </div>
                            </div>
                        </div>

                        <div ref={userMenuButtonRef}>
                            <Button color='tintDark'
                                    padding='small'
                                    className={style.userMenuButton}
                                    onClick={() => setShowUserMenu(value => !value)} >
                                <span className={style.usernameFlag}>{user.username}</span>
                                <img className={style.smallAvatar} src='/command-center/assets/default-usericon.png' role='presentation' />
                            </Button>
                        </div>

                        <div ref={userMenuRef} className={`${style.userMenu} ${showUserMenu ? '' : style.hidden} hasScrollBar`}>
                            <div className={style.iconNameRoleContainer}>
                                <img className={style.bigAvatar} src='/command-center/assets/default-usericon.png' role='presentation' />

                                <div className={style.nameRoleContainer}>
                                    <span className={style.username}>{user.username}</span>

                                    <span>
                                        <i18n.Message code={user.role.code} />
                                    </span>
                                </div>
                            </div>

                            <Button padding='small' href={`/command-center/user/edit/${user.id}`} >
                                <i18n.Message code='default.button.edit.user.label' />
                            </Button>

                            <Button filled={true}
                                    padding='small'
                                    href='/command-center/logout'>
                                <i18n.Message code='log.out' />
                            </Button>

                            <div className={style.versionAndCompanyNameContainer}>
                                <div className={style.version}>
                                    <i18n.Message code='commandcenter.verbose.version' args={[ version ]} />
                                </div>

                                <div className={style.companyName}>
                                    <i18n.Message code='company.name.message' args={[ companyName ]} />
                                </div>
                            </div>
                        </div>
                    </>
                }
            </div>
        </div>
    )
}
