import { useEffect } from 'react'

export default function useServerSentEvents(event, callback) {
    useEffect(() => {
        if (window.location.href.includes('login')) return
        try {
            SSE.addEventListener(event, callback)
        } catch (error) {
            console.error('An error occurred when trying to set up server sent events. This is likely because no SSE Event Source object could be found, which is normal and to be expected on the login page.')
            console.error(error)
            return
        }
        return () => SSE.removeEventListener(event, callback)
    }, [event, callback])
}
