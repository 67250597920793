.icon {
    width: auto;
    height: 100%;
    aspect-ratio: 1 / 1;
}

.icon svg {
    width: auto;
    height: 100%;
    aspect-ratio: 1 / 1;
}
