.topBar {
    width: 100%;
    height: calc(2 * var(--block-medium));
    margin: 0;
    padding: 0;
    padding-inline-start: var(--padding-small);
    padding-inline-end: calc(var(--padding-large) - var(--padding-small) - var(--border-thin));
    background-color: var(--color-bg0);
    box-shadow: var(--dimension-1);
    position: fixed;
    top: 0;
    left: 0;
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    align-items: center;
    column-gap: var(--padding-large);
    box-sizing: border-box;
    z-index: 10;
}

.topBar h1 {
    font-family: 'Hepta Slab', serif;
    font-size: 2em;
    font-weight: 600;
    color: var(--color-a0);
}

.logo {
    width: calc(1.5 * var(--block-large)) !important;
    height: calc(1.5 * var(--block-large)) !important;
    padding-inline-end: var(--padding-medium);
}

.itemGroup {
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    align-items: center;
    gap: var(--padding-large);
    min-width: 1px;
}

.latestVersionMessage,
.maintenanceModeMessage {
    padding: 2px;
    padding-inline: var(--padding-small);
    margin: 0;
    border-radius: var(--radius-medium);
}

.latestVersionMessage {
    background-color: var(--color-yellow);
    color: var(--color-fg2);
}

.maintenanceModeMessage {
    background-color: var(--color-red);
    color: var(--color-bg0);
}

.expiredSettingsButton {
    color: var(--color-red) !important;
}
.expiredSettingsButton:hover {
    color: var(--color-dark-red) !important;
}

.warnSettingsButton {
    color: var(--color-yellow) !important;
}
.warnSettingsButton:hover {
    color: var(--color-dark-yellow) !important;
}

.queueButtonContainer {
    position: relative;
}

.pulseRunning {
    position: absolute;
    top: calc(50% - 0.5 * var(--block-small));
    left: calc(50% - 0.5 * var(--block-small));
    border-radius: 50%;
    background-color: transparent;
    width: var(--block-small);
    height: var(--block-small);
    animation: pulse-running 600ms;
    z-index: -1;
}

@keyframes pulse-running {
    0% {
        box-shadow: 0 0 0 0 #1ebf61ff;
    }
    100% {
        box-shadow: 0 0 0 var(--block-small) #1ebf6100;
    }
}

.pulseQueued {
    position: absolute;
    top: calc(50% - 0.5 * var(--block-small));
    left: calc(50% - 0.5 * var(--block-small));
    border-radius: 50%;
    background-color: transparent;
    width: var(--block-small);
    height: var(--block-small);
    animation: pulse-queued 600ms;
    z-index: -1;
}

@keyframes pulse-queued {
    0% {
        box-shadow: 0 0 0 0 #dee04aff;
    }
    100% {
        box-shadow: 0 0 0 var(--block-small) #dee04a00;
    }
}

.singleQueuedJob path[data-icon-piece*="single"],
.multipleQueuedJobs path[data-icon-piece*="multiple"] {
    color: var(--color-dark-yellow);
}

.runningJobs path[data-icon-piece*="running"] {
    color: var(--color-dark-green);
}

.queue {
    z-index: 20;

    box-sizing: border-box;
    width: calc(17 * var(--block-medium));
    max-height: calc(100vh - 2 * var(--block-large) - var(--padding-large));

    position: fixed;
    top: calc(2 * var(--block-large));
    right: var(--padding-large);

    display: flex;
    flex-flow: column nowrap;
    justify-content: start;
    align-items: stretch;
    gap: var(--padding-medium);

    padding: var(--padding-large);
    box-sizing: border-box;
    background-color: var(--color-bg0);
    box-shadow: var(--dimension-1);
    border-radius: var(--radius-loose);
    border-width: 0;
    border-style: none;

    visibility: visible;

    transition: var(--transition-fast);
}

.queueGroup {
    width: 100%;
    font-size: 0.7rem;
    display: flex;
    flex-flow: column nowrap;
    justify-content: start;
    align-items: stretch;
    gap: var(--padding-small);
    position: relative;
}

.queueGroup h3 {
    font-family: 'Hepta Slab', serif;
    font-size: 1rem;
    font-weight: 400;
    color: var(--color-fg1);
}

.queueList {
    display: grid;
    gap: var(--padding-small);
    position: relative;
    grid-template-columns: calc(var(--block-small) + 0.5 * var(--padding-small)) 1fr min-content min-content;
}

.jobMessageArea {
    overflow-wrap: anywhere;
}

.queueList p {
    grid-column: 1 / 5;
}

.jobIcon {
    width: var(--block-small);
    height: var(--block-small);
    box-sizing: border-box;
}

.clearQueueButton {
    position: absolute;
    top: 0;
    right: 0;
    z-index: 21;
    color: var(--color-fg2);
    margin-inline-end: var(--padding-medium);
}

.runningJobItem,
.queuedJobItem {
    width: 100%;
    min-height: var(--block-medium);
    padding-inline: var(--padding-small);
    padding-block: calc(0.5 * var(--padding-small));
    grid-column: 1 / 5;
    display: grid;
    grid-template-columns: subgrid;

    border-radius: var(--radius-medium);
    border-width: 0;
    border-style: none;
    color: var(--color-fg0);

    align-items: center;

    box-sizing: border-box;
}

.runningJobItem {
    background-color: var(--color-light-green);
}

.queuedJobItem {
    background-color: var(--color-light-yellow);
}

.runningJobItem .hoverButton,
.queuedJobItem .hoverButton {
    visibility: hidden;
}

.runningJobItem:hover .hoverButton,
.queuedJobItem:hover .hoverButton {
    visibility: visible;
}

.usernameFlag {
    padding-block-start: calc(0.5 * var(--padding-small));
    padding-block-end: calc(0.5 * var(--padding-small));
    padding-inline-start: var(--padding-small);
    padding-inline-end: var(--padding-large);
    margin-inline-end: calc(-1 * (var(--padding-large) - var(--padding-small)));
    border-radius: var(--radius-medium);
    background-color: var(--color-bg2);
    color: var(--color-fg2);
    font-size: 0.7rem;
    transition: var(--transition-fast);
}

.userMenuButton:hover .usernameFlag {
    color: var(--color-fg0);
    background-color: var(--color-bg3);
}

.smallAvatar {
    z-index: 11;

    box-sizing: border-box;
    padding: 0;

    border-style: none;
    border-width: 0;
    border-radius: var(--radius-medium);
    overflow: hidden;

    width: var(--block-medium);
    height: var(--block-medium);
}

.userMenu {
    z-index: 20;

    box-sizing: border-box;
    width: calc(13 * var(--block-medium));
    max-height: calc(100vh - 2 * var(--block-large) - var(--padding-large));

    position: fixed;
    top: calc(2 * var(--block-large));
    right: var(--padding-large);

    display: flex;
    flex-flow: column nowrap;
    justify-content: start;
    align-items: stretch;
    gap: var(--padding-medium);

    padding: var(--padding-large);
    box-sizing: border-box;
    background-color: var(--color-bg0);
    box-shadow: var(--dimension-1);
    border-radius: var(--radius-loose);
    border-width: 0;
    border-style: none;

    visibility: visible;

    transition: var(--transition-fast);
}

.iconNameRoleContainer {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    align-items: center;
    column-gap: var(--padding-small);

    color: var(--color-fg1);
}

.bigAvatar {
    width: calc(2 * var(--block-large));
    height: calc(2 * var(--block-large));

    border-style: none;
    border-width: 0;
    border-radius: var(--radius-loose);
}

.nameRoleContainer {
    display: flex;
    flex-flow: column;
    justify-content: start;
    align-items: end;
    gap: var(--padding-small);
}

.username {
    font-family: 'Hepta Slab', serif;
    font-size: 1rem;
    font-weight: 400;
    overflow-wrap: anywhere;
}

.versionAndCompanyNameContainer {
    display: flex;
    flex-flow: column;
    justify-content: start;
    align-items: start;
    gap: var(--padding-small);
}

.version,
.companyName {
    color: var(--color-fg2);
    align-self: start;
    font-size: 0.8rem;
}

.hidden {
    top: -100vh !important;
    visibility: hidden !important;
}
