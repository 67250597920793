import useServerSentEvents from '../useServerSentEvents'
import { createContext, useRef, useState } from 'react'

export const TopBarContext = createContext(null)

export function TopBarProvider({ value, isLogin, children }) {
    const [ isMaintenanceMode, setIsMaintenanceMode ] = useState(value.isMaintenanceMode)
    const [ areJobsRunning, setAreJobsRunning ] = useState(value.areJobsRunning)
    const [ runningJobs, setRunningJobs ] = useState(value.runningJobs)
    const [ queuedJobs, setQueuedJobs ] = useState(value.queuedJobs)

    const queueEventTarget = useRef(new EventTarget())

    useServerSentEvents('maintenancemodeupdated', event => {
        setIsMaintenanceMode(event.detail.isMaintenanceMode)
        setAreJobsRunning(event.detail.areJobsRunning)
    })

    useServerSentEvents('queueupdated', event => {
        setRunningJobs(event.detail.runningJobs)
        setQueuedJobs(event.detail.queuedJobs)

        if (event.detail.runningJobs.some(job => !runningJobs.some(j => j.id === job.id))) {
            queueEventTarget.current.dispatchEvent(new Event('newrunningjob'))
        }

        if (event.detail.queuedJobs.some(job => !queuedJobs.some(j => j.id === job.id))) {
            queueEventTarget.current.dispatchEvent(new Event('newqueuedjob'))
        }
    })

    return (
        <TopBarContext.Provider value={{ ...value, isMaintenanceMode, areJobsRunning, runningJobs, queuedJobs, queueEventTarget }} >
            {children}
        </TopBarContext.Provider>
    )
}
