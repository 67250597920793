import { SvgIconProvider } from '../SvgIcon/SvgIconContext'
import { TopBarProvider } from './TopBarContext'

import TopBar from './TopBar'

import ReactDOM from 'react-dom/client'

window.addEventListener('DOMContentLoaded', () => {
    const root = document.getElementById('top-bar-react-root')

    const topBarContextValue = JSON.parse(root.dataset.topbarcontext)
    const isLogin = root.dataset.islogin === 'true'

    ReactDOM.createRoot(root).render(
        <SvgIconProvider>
            <TopBarProvider value={topBarContextValue}>
                <TopBar isLogin={isLogin} />
            </TopBarProvider>
        </SvgIconProvider>
    )
})
