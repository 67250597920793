import { SvgIconContext } from './SvgIconContext'

import * as style from './SvgIcon.module.css'

import { useContext, useEffect, useState } from 'react'

export default function SvgIcon({ src, alt, className }) {
    const [ previousSrc, setPreviousSrc ] = useState(src)
    const [ previousAlt, setPreviousAlt ] = useState(alt)
    const [ svg, setSvg ] = useState('')
    const { icons, fetchIcon } = useContext(SvgIconContext)

    useEffect(() => {
        if (!svg.trim() || src !== previousSrc || alt !== previousAlt) {
            if (!alt?.trim()) alt = 'noalt'
            const icon = icons.find(icon => icon.src === src && icon.alt === alt)
            setSvg(icon?.icon ?? fetchIcon(src, alt))
            setPreviousSrc(src)
            setPreviousAlt(alt)
        }
    }, [icons, previousSrc, src, previousAlt, alt])

    const classList = [ style.icon, ...(className ? [ className ] : []) ]
    
    return <div className={classList.join(' ')} dangerouslySetInnerHTML={{ __html: svg }}></div>
}
