// using svgIconEventTarget and fetchSvgContent from /grails-app/assets/javascrips/fetchsvgcontent.js
import { createContext, useEffect, useState } from 'react'

export const SvgIconContext = createContext({})

export function SvgIconProvider({ children }) {
    const [ icons, setIcons ] = useState([])

    useEffect(() => {
        function newIconEventHandler(event) {
            if (!event.detail?.icons) return
            setIcons(icons => [ ...icons, ...event.detail.icons ])
        }
        svgIconEventTarget.addEventListener('newicons', newIconEventHandler)
        return () => svgIconEventTarget.removeEventListener('newicons', newIconEventHandler)
    }, [icons])

    function fetchIcon(src, alt) {
        fetchSvgContent(src, alt)
        return ''
    }

    return (
        <SvgIconContext.Provider value={{ icons, fetchIcon }}>
            {children}
        </SvgIconContext.Provider>
    )
}
