// this is file is just re-exports of the $i18n.m() function with
// different syntaxes that work better with the rest of the webapp

function getMessage(code, args = []) {
    return $i18n.m(code, args)
}

function Message({ code, args = [] }) {
    return <>{getMessage(code, args)}</>
}

export { Message, getMessage }
