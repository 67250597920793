import * as style from './Button.module.css';

export default function Button({
    size = 'large',
    padding = 'default',
    color = 'primary',
    filled = false,
    disabled = false,
    title,
    href,
    target,
    onClick,
    className,
    children,
    type = 'button' // Add type prop with default value 'button'
}) {
    const classList = [
        style.button,
        getClassNameForSize(size),
        getClassNameForPadding(padding),
        getClassNameForColor(color),
        ...(filled ? [style.filled] : []),
        ...(className ? [className] : []),
    ];

    if (href?.trim() && !disabled) return (
        <a href={href}
           target={target}
           className={classList.join(' ')}
           title={title} >
            {children}
        </a>
    );

    return (
        <button className={classList.join(' ')}
                onClick={onClick}
                disabled={disabled}
                type={type} // Use the type prop here
                title={title} >
            {children}
        </button>
    );
}

function getClassNameForSize(size) {
    switch (size) {
        case 'large': return style.large;
        case 'medium': return style.medium;
        case 'small': return style.small;
        default: return style.large;
    }
}

function getClassNameForPadding(padding, size) {
    switch (padding) {
        case 'large': return style.largePadding;
        case 'medium': return style.mediumPadding;
        case 'small': return style.smallPadding;
        case 'none': return style.noPadding;
        default: switch (size) {
            case 'large': return style.largePadding;
            case 'medium': return style.mediumPadding;
            case 'small': return style.smallPadding;
            default: return style.largePadding;
        }
    }
}

function getClassNameForColor(color) {
    const dark = ['dark', 'tint-dark', 'tintDark', 'none'];
    const light = ['light', 'tint-light', 'tintLight'];
    const red = ['red', 'red-button', 'redButton'];
    const fg = ['fg', 'foreground'];
    const bg = ['bg', 'background'];
    const accentPrimary = ['accent-a', 'accentA', 'accent-primary', 'accentPrimary', 'primary'];
    const accentSecondary = ['accent-b', 'accentB', 'accent-secondary', 'accentSecondary', 'secondary'];
    const accentTertiary = ['accent-c', 'accentC', 'accent-tertiary', 'accentTertiary', 'tertiary'];

    switch (true) {
        case dark.includes(color): return style.dark;
        case light.includes(color): return style.light;
        case red.includes(color): return style.red;
        case bg.includes(color): return style.bg;
        case fg.includes(color): return style.fg;
        case accentPrimary.includes(color): return style.accentPrimary;
        case accentSecondary.includes(color): return style.accentSecondary;
        case accentTertiary.includes(color): return style.accentTertiary;
        default: return style.accentPrimary;
    }
}
